import React from 'react';

const Home = () => {
    return (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <h2 className="p-5" >Welcome to VHDL-VIZ!</h2>
      </div>
    );
}

export default Home;