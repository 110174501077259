import React from 'react';

/*

  const [packagesData, setPackagesData] = useState([])

  const fetchPackageData = async () => {
    const response = await fetch("http://10.0.0.4:8000/packages/")
    const jsonData = await response.json()
    setPackagesData(jsonData)
  }

*/

const Login = () => {
    return (
      <div>
          <h2>Login</h2>
      </div>
    );
}

export default Login;