import React from 'react';

const Editor = () => {
    return (
      <div>
          <h2>Awesome Editor!</h2>
      </div>
    );
}

export default Editor; 