import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'
import { useNavigate } from 'react-router';

import Header from './Header';
import Home from './Home';
import Editor from './Editor';
import Login from './Login';
import About from './About';

const pages = {
  HOME: "/",
  LOGIN: "/login",
  EDITOR: "/edit",
  ABOUT: "/about"
}

const App = () => {

  const navigate = useNavigate()

  const loadPage = (page) => {
    navigate(page);
  }

  return (
    <div>
      <Header loadPage={loadPage} pages={pages}/>
      <Routes>
        <Route exact path={pages.HOME} element={<Home />}/>
        <Route exact path={pages.EDITOR} element={<Editor />}/>
        <Route exact path={pages.LOGIN} element={<Login />}/>
        <Route exact path={pages.ABOUT} element={<About />}/>
      </Routes>
    </div>
  );
}

export default App;
