import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const Header = ({ loadPage, pages }) => {
    return (
      <div>
            <Navbar collapseOnSelect bg="light" expand="lg">
            <Navbar.Brand >VHDL-VIZ</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={() => loadPage(pages.HOME)}>Home</Nav.Link>
                <Nav.Link onClick={() => loadPage(pages.EDITOR)}>Editor</Nav.Link>
                <Nav.Link onClick={() => loadPage(pages.LOGIN)}>Login</Nav.Link>
                <Nav.Link onClick={() => loadPage(pages.ABOUT)}>About</Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      </div>
    );
}

export default Header;